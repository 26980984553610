import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Device} from "../../../model/Device";
import {ValueObject} from "../../../model/NetworkObjects";
import {NetworkObejcteStore} from "../../../store/network-obejcte.store";
import {DeviceService} from "../../../Service/device.service";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-source-dialog',
  templateUrl: './source-dialog.component.html',
  styleUrl: './source-dialog.component.scss'
})
export class SourceDialogComponent implements OnInit{
  selectedValue: string | null = null;
  selectedDevice!: string ;
  devices: Device[] = [];
  values: ValueObject[] = [];
  filteredValues: ValueObject[]  = [];
  filter: string = '';
  loading:boolean = true;
  device: any | null = null;
  selectedFormat: string = 'ip';
  inputValue: string = '';
  startIP: string = '';
  endIP : string = '';
  dns : string = ''
  currentPage: number = 1;
  itemsPerPage: number = 5;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private networkObjecte: NetworkObejcteStore,
              private deviceServices: DeviceService,
              public dialogRef: MatDialogRef<SourceDialogComponent>
  ) {}



  ngOnInit(): void {this.deviceServices.device$.subscribe((device) => {
      this.device = device;
      if (device){
        this.networkObjecte.getNetworkObejecte(device.display_name);
        this.networkObjecte.networkObject$.subscribe(data => {
          if (data){
            this.values = data?.entitiesReponses.flatMap(entity => entity.values);
            this.loading = false;
            this.filteredValues = this.values;
          }
        });
      }
    });
  }


  selectValue(valueName: string): void {
    this.filter = valueName;
  }


  addressWasChanged() {
    if (this.filter) {
      const trimmedFilter = this.filter.trim().toLowerCase();
      this.filteredValues = this.values.filter(value =>
        value.name.toLowerCase().includes(trimmedFilter)
      );
    } else {
      this.filteredValues = [...this.values];
    }
  }



  saveAndClose(){
    this.dialogRef.close({
      selectedValue: this.filter ? this.filter : this.getvalues()
    });
  }
  getvalues() {
    if (this.dns){
      return this.dns;
    }
    switch (this.selectedFormat) {
      case 'ip':
        return this.inputValue;
      case 'ip-range':
        return this.startIP + '-' +this.endIP;
      case 'cidr':
        return this.startIP + '/' +this.endIP;
      case 'netmask':
        return this.startIP + '/' +this.endIP;
      case 'any':
        return 'any';
      default:
        return 'Enter value';
    }

  }

  get paginatedValues(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredValues.slice(startIndex, endIndex);
  }


  onPageChange(event: PageEvent): void {
    this.itemsPerPage = event.pageSize;
    this.currentPage = event.pageIndex + 1;
  }


}
