import { NgModule } from '@angular/core';
import { RegelAendernComponent } from './regel-aendern.component';
import { SharedModule } from '../../Shared/shared.module';
import {RegelaendernRoutingModule} from "./regelaendern-routing.module";
import {SelectRuleModifyComponent} from "./select-rule-modify/select-rule-modify.component";
import {SelectRuleModifyDialogComponent} from "./select-rule-modify-dialog/select-rule-modify-dialog.component";
import {MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle} from "@angular/material/dialog";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatSort} from "@angular/material/sort";
import {TrafficTableComponentComponent} from "./traffic-table-component/traffic-table-component.component";
import {FormsModule} from "@angular/forms";
import {SourceDialogComponent} from "./source-dialog/source-dialog.component";
import {ServiceDialogComponent} from "./service-dialog/service-dialog.component";
import {RegelErstellenModule} from "../regel-erstellen/regel-erstellen.module";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {MatPaginator} from "@angular/material/paginator";

@NgModule({
  declarations: [
    RegelAendernComponent,
    SelectRuleModifyComponent,
    SelectRuleModifyDialogComponent,
    TrafficTableComponentComponent,
    SourceDialogComponent,
    ServiceDialogComponent
  ],
  exports: [
    SelectRuleModifyComponent
  ],
    imports: [
        RegelaendernRoutingModule,
        SharedModule,
        MatDialogActions,
        MatDialogContent,
        MatCheckbox,
        MatSort,
        FormsModule,
        MatDialogTitle,
        MatDialogClose,
        RegelErstellenModule,
        MatProgressSpinner,
        MatPaginator,
    ]
})
export class RegelAendernModule { }
