import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Rule} from "../../../model/FirewallRule";
import {FirewallRulesStore} from "../../../store/firewall-rules-store";


@Component({
  selector: 'app-select-rule-modify',
  templateUrl: './select-rule-modify.component.html',
  styleUrl: './select-rule-modify.component.scss'
})
export class SelectRuleModifyComponent implements OnInit{
  displayedColumns: string[] = ['edit', 'number', 'enabled', 'source', 'user', 'destination', 'service', 'action', 'logging', 'time', 'comment', 'cliView'];
  dataSource: Rule[] = [];
  selctedRule! :Rule

  @Output() valuesSelected = new EventEmitter<{ selectedRules: Rule }>();

  constructor(private ruleStore: FirewallRulesStore) { }

  ngOnInit(): void {
    this.ruleStore.rules$.pipe().subscribe(data => {
      if (data) {
        this.dataSource = data.flatMap(response => response.rules);
      }
    });
  }


  editRule(rule: Rule) {
    this.selctedRule = rule;
    this.emitSelectedValues();

  }
  emitSelectedValues() {
    this.valuesSelected.emit({ selectedRules: this.selctedRule });
  }

}
