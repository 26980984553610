import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {NetworkObejcteStore} from "../../../store/network-obejcte.store";
import {DeviceService} from "../../../Service/device.service";
import {Device} from "../../../model/Device";
import {ValueService} from "../../../model/NetworkService";
import {PageEvent} from "@angular/material/paginator";

@Component({
  selector: 'app-service-dialog',
  templateUrl: './service-dialog.component.html',
  styleUrl: './service-dialog.component.scss'
})
export class ServiceDialogComponent implements OnInit{

  selectedValue: string | null = null;
  selectedDevice!: string ;
  devices: Device[] = [];
  values: ValueService[] = [];
  filteredValues: ValueService[]  = [];
  filter: string = '';
  loading:boolean = true;
  device: any | null = null;
  currentPage: number = 1;
  itemsPerPage: number = 5;



  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private networkObjecte: NetworkObejcteStore,
              private deviceServices: DeviceService,
              public dialogRef: MatDialogRef<ServiceDialogComponent>
  ) {}

  ngOnInit(): void {this.deviceServices.device$.subscribe((device) => {
    this.device = device;
    if (!device) {
      return;
    }
    this.networkObjecte.getNetworkService(device.display_name);
    this.networkObjecte.networkService$.subscribe(data => {
      if (data) {
        this.values = data?.entitiesReponses.flatMap(entity => entity.values);
        this.loading = false;
        this.filteredValues = this.values;
      }
    });
  });
  }

  selectValue(valueName: string): void {
    this.filter = valueName;
  }


  addressWasChanged() {
    if (this.filter) {
      const trimmedFilter = this.filter.trim().toLowerCase();
      this.filteredValues = this.values.filter(value =>
        value.name.toLowerCase().includes(trimmedFilter)
      );
    } else {
      this.filteredValues = [...this.values];
    }
  }

  get paginatedValues(): any[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filteredValues.slice(startIndex, endIndex);
  }


  onPageChange(event: PageEvent): void {
    this.itemsPerPage = event.pageSize;
    this.currentPage = event.pageIndex + 1;
  }


  saveAndClose(){
    this.dialogRef.close({
      selectedValue: this.filter,
    });
  }
}
