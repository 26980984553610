<form class="main-container" [formGroup]="trafficForm">
  <!-- Sources Section -->
  <div formArrayName="sources" class="config-section">
    <div *ngFor="let control of sourcesArray.controls; let i = index">
      <mat-form-field class="input-field" appearance="outline">
        <input matInput placeholder="Source {{i + 1}}" [formControlName]="i">
        <button mat-icon-button matSuffix (click)="openSourceDialog(i)">
          <mat-icon>{{ opendialog ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
        </button>
      </mat-form-field>

    </div>
  </div>

  <!-- Destinations Section -->
  <div formArrayName="destinations" class="config-section">
    <div *ngFor="let control of destinationsArray.controls; let i = index">
      <mat-form-field class="input-field" appearance="outline">
        <input matInput placeholder="Destination {{i + 1}}" [formControlName]="i">
        <button mat-icon-button matSuffix (click)="openDestDialog(i)">
          <mat-icon>{{ opendialog ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>

  <!-- Services Section -->
  <div formArrayName="services" class="config-section">
    <div *ngFor="let control of servicesArray.controls; let i = index">
      <mat-form-field class="input-field" appearance="outline">
        <input matInput placeholder="Service {{i + 1}}" [formControlName]="i">
        <button mat-icon-button matSuffix (click)="openServiceDialog(i)">
          <mat-icon>{{ opendialog ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
</form>
