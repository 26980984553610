import { Injectable } from '@angular/core';
import {Actions, RequestActions} from "../model/RuleModify";

@Injectable({
  providedIn: 'root'
})
export class RuleModifyService {

  constructor() { }

  checkRuleAction(existingRule: any, newRule: any): any {
    const requestActions :RequestActions = {
      ruleId: existingRule.ruleId,
      source: {
        actions: [] as Actions[]
      },
      destination: {
        actions: [] as  Actions[]
      },
      service: {
        actions: [] as  Actions[]
      }
    };

    this.processField('source', existingRule.source, newRule.sources, requestActions.source.actions);
    this.processField('destination', existingRule.destination, newRule.destinations, requestActions.destination.actions);
    this.processField('service', existingRule.service, newRule.services, requestActions.service.actions);

    return { requestActions: [requestActions] };
  }

  private processField(field: string, existingValues: string[], newValues: string[], actionsArray: { action: string, item: string }[]) {
    existingValues.forEach(item => {
      if (!newValues.includes(item)) {
        actionsArray.push({ action: 'remove', item });
      }
    });

    newValues.forEach(item => {
      if (!existingValues.includes(item)) {
        actionsArray.push({ action: 'add', item });
      }
    });
  }
}
