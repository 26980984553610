
<mat-card class="create-request-card">
  <mat-card-title>Create a New Change Request</mat-card-title>
  <mat-card-header>BWI Rule Modification Request</mat-card-header>
  <br>
  <mat-divider></mat-divider>
  <mat-card-content>
    <form [formGroup]="ruleChangeForm">
      <div class="form-header">
        <div class="form-buttons">
          <button mat-fab extended color="primary" type="submit" (click)="onSaveDraft()">Save Draft</button>
          <button mat-fab extended color="primary" type="button" (click)="nextStep()">Next</button>
        </div>
      </div>
      <div>
        <p>Create a New Change Request </p>
      </div>

      <div>
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Subject</mat-label>
          <input matInput placeholder="Enter subject" formControlName="subject">
          <mat-error *ngIf="ruleChangeForm.get('subject')?.hasError('required')">
            Subject is required
          </mat-error>
        </mat-form-field>
      </div>



      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Describe the Issue</mat-label>
        <textarea matInput formControlName="issueDescription"></textarea>
      </mat-form-field>


      <div class="form-group">
        <label class="attachments-label">Attachments</label>
        <input type="file" multiple (change)="onFileSelected($event)" #fileInput>
        <button type="button" mat-fab extended color="primary" (click)="fileInput.click()">
          <mat-icon>attach_file</mat-icon>
          Add more files
        </button>
        <ul>
          <li *ngFor="let item of files">{{ item.name }}</li>
        </ul>
      </div>

      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Due</mat-label>
        <input matInput [matDatepicker]="picker1" formControlName="due">
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <div class="form-row">
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Requestor</mat-label>
          <input matInput required placeholder="Enter requestor" formControlName="requestor" readonly>
          <mat-error *ngIf="ruleChangeForm.get('requestor')?.hasError('required')">
            Requestor is required
          </mat-error>
          <mat-error *ngIf= "ruleChangeForm.get('requestor')?.hasError('email') && ruleChangeForm.get('requestor')?.touched">
            Please enter a valid email address.
          </mat-error>
        </mat-form-field>
      </div>

      <div>
        <mat-form-field class="input-field" appearance="outline">
          <mat-label>Requestor Group</mat-label>
          <mat-select formControlName="requestorGroup">
            <mat-option value="group1">Group 1</mat-option>
            <mat-option value="group2">Group 2</mat-option>
          </mat-select>
          <mat-error *ngIf="ruleChangeForm.get('requestorGroup')?.hasError('required') && ruleChangeForm.get('requestorGroup')?.touched">
            Requestor Group is required.
          </mat-error>
        </mat-form-field>
      </div>



      <mat-form-field class="input-field" appearance="outline">
        <mat-label>Device Name</mat-label>
        <mat-select required formControlName="deviceName" (selectionChange)="onDeviceChange($event)">
          <mat-option *ngFor="let device of devices" [value]="device.display_name">{{ device.display_name }}</mat-option>
        </mat-select>
        <mat-error *ngIf="ruleChangeForm.get('deviceName')?.hasError('required')">
          Device Name is required
        </mat-error>
      </mat-form-field>

    </form>
  </mat-card-content>
</mat-card>


<div class="form-container">
  <mat-label>Rule to modify:</mat-label>
  <mat-card>
    <mat-card-content>
      <div>
        <button mat-fab extended color="primary" (click)="openSelectRuleDialog()" [disabled]="!selectedDevice">Select Rules</button>
      </div>
      <div *ngIf="selctedRules" class="selected-rule-table">
        <table mat-table [dataSource]="[selctedRules ]" class="mat-elevation-z8">

          <!-- Source Column -->
          <ng-container matColumnDef="source">
            <th mat-header-cell *matHeaderCellDef>SOURCE</th>
            <td mat-cell *matCellDef="let rule">{{ rule.source.join(', ') }}</td>
          </ng-container>

          <!-- User Column -->
          <ng-container matColumnDef="user">
            <th mat-header-cell *matHeaderCellDef>USER</th>
            <td mat-cell *matCellDef="let rule">{{ rule.user }}</td>
          </ng-container>

          <!-- Destination Column -->
          <ng-container matColumnDef="destination">
            <th mat-header-cell *matHeaderCellDef>DESTINATION</th>
            <td mat-cell *matCellDef="let rule">{{ rule.destination.join(', ') }}</td>
          </ng-container>

          <!-- Service Column -->
          <ng-container matColumnDef="service">
            <th mat-header-cell *matHeaderCellDef>SERVICE</th>
            <td mat-cell *matCellDef="let rule">{{ rule.service.join(', ') }}</td>
          </ng-container>

          <!-- Action Column -->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef>ACTION</th>
            <td mat-cell *matCellDef="let rule">{{ rule.action }}</td>
          </ng-container>

          <!-- Comment Column -->
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef>COMMENT</th>
            <td mat-cell *matCellDef="let rule">{{ rule.comments.join(', ') }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['source', 'user', 'destination', 'service', 'action', 'comment']"></tr>
          <tr mat-row *matRowDef="let row; columns: ['source', 'user', 'destination', 'service', 'action', 'comment']"></tr>
        </table>

        <mat-divider class="divider"></mat-divider>
      </div>

    <div *ngIf="selctedRules">
      <app-traffic-table-component (combinedFormValuesEmitted)="onFormValuesEmitted($event)"></app-traffic-table-component>
    </div>

    </mat-card-content>
  </mat-card>

  <mat-label>External change request id</mat-label>
  <mat-form-field appearance="outline" class="input-field">
    <input matInput placeholder="">
  </mat-form-field>


  <div class="form-header">
    <button mat-fab extended color="primary" class="back-button" type="button" (click)="prevStep()">Back</button>
    <div class="form-buttons">
      <button mat-fab extended color="primary" type="submit" (click)="onSaveDraft()">Save Draft</button>
      <button mat-fab extended color="primary" type="button" (click)="nextStep()">Next</button>
    </div>
  </div>
</div>
