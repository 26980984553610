import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { SourceDialogComponent } from "../source-dialog/source-dialog.component";
import { ServiceDialogComponent } from "../service-dialog/service-dialog.component";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { DeviceService } from "../../../Service/device.service";
import { debounceTime, take } from "rxjs";

@Component({
  selector: 'app-traffic-table-component',
  templateUrl: './traffic-table-component.component.html',
  styleUrl: './traffic-table-component.component.scss'
})
export class TrafficTableComponentComponent implements OnInit {
  opendialog: boolean = false;


  trafficForm: FormGroup;

  @Output() combinedFormValuesEmitted = new EventEmitter<any>();

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private deviceService: DeviceService,
  ) {
    this.trafficForm = this.fb.group({
      sources: this.fb.array([this.createField(), this.createField()]),
      destinations: this.fb.array([this.createField(), this.createField()]),
      services: this.fb.array([this.createField(), this.createField()])
    });

    this.observeLastFormControl(this.sourcesArray);
    this.observeLastFormControl(this.destinationsArray);
    this.observeLastFormControl(this.servicesArray);

    this.trafficForm.valueChanges.pipe(debounceTime(300)).subscribe(() => {
      this.emitFormValues();
    });
  }

  createField(value: string = ''): FormControl {
    return this.fb.control(value);
  }

  get sourcesArray(): FormArray {
    return this.trafficForm.get('sources') as FormArray;
  }

  get destinationsArray(): FormArray {
    return this.trafficForm.get('destinations') as FormArray;
  }

  get servicesArray(): FormArray {
    return this.trafficForm.get('services') as FormArray;
  }

  observeLastFormControl(formArray: FormArray): void {
    const lastControl = formArray.at(formArray.length - 1) as FormControl;
    lastControl.valueChanges.pipe(take(1)).subscribe(() => {
      if (lastControl.valid && lastControl.value && formArray.length - 1 === formArray.controls.indexOf(lastControl)) {
        formArray.push(this.createField());
        this.observeLastFormControl(formArray);
      }
    });
  }

  openDialog(dialogComponent: any, type: string, formArray: FormArray, index: number, sizeConfig = { minWidth: '800px', height: '700px' }): void {
    this.opendialog = true
    const dialogRef = this.dialog.open(dialogComponent, {
      ...sizeConfig,
      data: { type }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.selectedValue) {
        formArray.at(index).setValue(result.selectedValue);
        this.opendialog = false;
      }
    });
  }

  openSourceDialog(i: number): void {
    this.openDialog(SourceDialogComponent, 'source', this.sourcesArray, i);
  }

  openDestDialog(i: number): void {
    this.openDialog(SourceDialogComponent, 'destination', this.destinationsArray, i);
  }

  openServiceDialog(i: number): void {
    this.openDialog(ServiceDialogComponent, 'service', this.servicesArray, i);
  }

  ngOnInit(): void {
    this.deviceService.rule$.subscribe(rule => {
      // Set rule values for sources, destinations, and services
      if (rule.source) {
        const sourceValues = Array.isArray(rule.source) ? rule.source : [rule.source];
        this.setFormArrayValues(this.sourcesArray, sourceValues);
      }

      if (rule.destination) {
        const destinationValues = Array.isArray(rule.destination) ? rule.destination : [rule.destination];
        this.setFormArrayValues(this.destinationsArray, destinationValues);
      }

      if (rule.service) {
        const serviceValues = Array.isArray(rule.service) ? rule.service : [rule.service];
        this.setFormArrayValues(this.servicesArray, serviceValues);
      }
    });
  }

  setFormArrayValues(formArray: FormArray, values: string[]): void {
    formArray.clear(); // Clear existing form controls
    values.forEach(value => formArray.push(this.createField(value))); // Populate with given values
    formArray.push(this.createField()); // Add one empty field
    this.observeLastFormControl(formArray); // Observe last control
  }

  emitFormValues(): void {
    const formValue = this.trafficForm.value;
    const filteredFormValue = {
      sources: formValue.sources.filter((value: any) => value !== null && value !== undefined && value !== ''),
      destinations: formValue.destinations.filter((value: any) => value !== null && value !== undefined && value !== ''),
      services: formValue.services.filter((value: any) => value !== null && value !== undefined && value !== '')
    };
    this.combinedFormValuesEmitted.emit(filteredFormValue);
  }
}
