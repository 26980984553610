import {Component, Inject} from '@angular/core';
import {Rule} from "../../../model/FirewallRule";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SelectRuleComponent} from "../../regel-loeshen/select-rule/select-rule.component";


@Component({
  selector: 'app-select-rule-modify-dialog',
  templateUrl: './select-rule-modify-dialog.component.html',
  styleUrl: './select-rule-modify-dialog.component.scss'
})
export class SelectRuleModifyDialogComponent {
  selectedValue! : Rule;
  constructor(public dialogRef: MatDialogRef<SelectRuleComponent>,
              @Inject(MAT_DIALOG_DATA) public data: Rule[]
  ) {}


  onCansel(){
    this.dialogRef.close();
  }

  receiveValues(selectedValue: Rule) {
    this.selectedValue = selectedValue;
    this.dialogRef.close({
      selectedValue: this.selectedValue,
    });
  }

}
