import {Component,  OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AlertService} from "../../Shared/alert.service";
import {Device} from "../../model/Device";
import {Rule} from "../../model/FirewallRule";
import {DevicestoreService} from "../../store/devicestore.service";
import {FirewallRulesStore} from "../../store/firewall-rules-store";
import {ChangeRequestRepoService} from "../../repo/change-request-repo.service";
import {MatDialog} from "@angular/material/dialog";
import {SelectRuleModifyDialogComponent} from "./select-rule-modify-dialog/select-rule-modify-dialog.component";
import {DeviceService} from "../../Service/device.service";
import {RuleModificationRequest} from "../../model/RuleModify";
import {CustomFieldKeys, FieldKeys, TemplateName} from "../../model/FieldKeys";
import {RuleModifyService} from "../../Service/rule-modify.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {ConfirmDialogComponent} from "../../confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-regel-aendern',
  templateUrl: './regel-aendern.component.html',
  styleUrl: './regel-aendern.component.scss'
})
export class RegelAendernComponent implements  OnInit{
  files: File[] = [];
  ruleChangeForm: FormGroup;
  devices: Device[] = [];
  selectedDevice: Device | null = null;
  selctedRules! : Rule ;
  changeRequestId: number | undefined
  isDialogOpen = false;
  newRule : any;

  constructor(private fb: FormBuilder,
              private alertService: AlertService,
              public deviceStore : DevicestoreService,
              public dialog: MatDialog,
              private ruleStore : FirewallRulesStore,
              private changeRequestRepoService : ChangeRequestRepoService,
              private deviceService: DeviceService,
              private ruleModifyService : RuleModifyService,
              private router: Router,



  ) {
    this.ruleChangeForm = this.fb.group({
      subject: ['', Validators.required],
      due: [''],
      issueDescription: [''],
      attachments: [''],
      requestor: ['customuser@test.de', [Validators.required, Validators.email]],
      requestorGroup: ['group1', Validators.required],
      deviceName: ['', Validators.required]
    });
    this.deviceStore.getDevices()

  }

  onSaveDraft() {
  const ruleModify = this.createRuleModify();
    console.log(ruleModify);
  }


  createRuleModify() : RuleModificationRequest{
    const fields = [
      { key: FieldKeys.Subject, values: [this.ruleChangeForm.get('subject')?.value] },
      { key: FieldKeys.Owner, values: ['api-admin-1@admin.de'] },
      { key: FieldKeys.ChangeRequestDescription, values: [this.ruleChangeForm.get('issueDescription')?.value] },
      { key: FieldKeys.Requestor, values: [this.ruleChangeForm.get('requestor')?.value] },
      { key: FieldKeys.Due, values: [this.ruleChangeForm.get('due')?.value] },
      { key: FieldKeys.Expire, values: [this.ruleChangeForm.get('expires')?.value] },
      { key: FieldKeys.Device, values: [this.selectedDevice?.name]},
      { key: CustomFieldKeys.RequestorGroup, values: [this.ruleChangeForm.get('requestorGroup')?.value] },

    ];
    return {
      template: TemplateName.FWRegelAendern,
      requestActions: this.newRule,
      fields: fields
    };


  }

  openSelectRuleDialog(): void {
    this.isDialogOpen = true;
    const dialogRef = this.dialog.open(SelectRuleModifyDialogComponent, {
      minWidth: '1200px',
      height: '800px',
      data: {},
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe(result => {
      this.isDialogOpen = false;
      this.selctedRules = result.selectedValue;
      this.deviceService.setRule(this.selctedRules);
    });
  }

  onDeviceChange(event: any) {
    this.selectedDevice = this.devices.find(device => device.display_name === event.value) || null;
    this.ruleStore.getRules(event.value);
    this.deviceService.setDevice(this.selectedDevice);
  }




  onFormValuesEmitted(values: any): void {
    this.newRule =  this.ruleModifyService.checkRuleAction(this.selctedRules , values);
  }


  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      for (let i = 0; i < input.files.length; i++) {
        this.files.push(input.files[i]);
      }

    }
  }

  nextStep() {
    this.onSaveDraft();
    this.alertService.alert$.subscribe((alertStatus) => {
      if (alertStatus.type === 'success') {
        this.router.navigate([environment.routes.auswahltemplate]);
      }
    });
  }

  prevStep() {
    this.openConfirmDialog();
  }

  ngOnInit(): void {
    this.deviceStore.devices$.subscribe(date =>{
      this.devices = date
    })
    this.deviceService.changeRequestId$.subscribe(id =>{
      this.changeRequestId = id;
    })

  }

  openConfirmDialog(): void {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.router.navigate([environment.routes.auswahltemplate])
      } else {
      }
    });
  }

}
