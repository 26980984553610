<h2 mat-dialog-title>{{ data?.type === 'source' ? 'Choose Source' : 'Choose Destination' }}</h2>
<mat-tab-group>
  <!-- IP Configuration Tab -->
  <mat-tab label="IP">

    <div class="radio-group-container">
      <div class="radio-group">
        <mat-label class="format-label">Format:</mat-label>
        <mat-radio-group [(ngModel)]="selectedFormat">
          <mat-radio-button class="radio-button" value="ip">IP</mat-radio-button>
          <mat-radio-button class="radio-button" value="ip-range">IP Range</mat-radio-button>
          <mat-radio-button class="radio-button" value="cidr">CIDR</mat-radio-button>
          <mat-radio-button class="radio-button" value="netmask">Netmask</mat-radio-button>
          <mat-radio-button class="radio-button" value="any">Any</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <!-- Eingabefeld für IP -->
    <div class="input-container" *ngIf="selectedFormat === 'ip'">
      <label class="input-label">IP:</label>
      <mat-form-field class="full-width input-field" appearance="outline">
        <input matInput [(ngModel)]="inputValue">
        <mat-hint>e.g. 192.168.2.128</mat-hint>
      </mat-form-field>
    </div>

    <!-- Zwei Eingabefelder für IP Range -->
    <div class="input-container" *ngIf="selectedFormat === 'ip-range'">
      <label class="input-label">IP Range:</label>
      <div class="ip-range-inputs">
        <mat-form-field class="full-width input-field" appearance="outline">
          <input matInput [(ngModel)]="startIP">
          <mat-hint>e.g. 192.168.2.1 - 192.168.2.255</mat-hint>
        </mat-form-field>
        <span class="range-separator">-</span>
        <mat-form-field class="full-width input-field" appearance="outline">
          <input matInput [(ngModel)]="endIP">
        </mat-form-field>
      </div>
    </div>

    <div class="input-container" *ngIf="selectedFormat === 'cidr'">
      <label class="input-label">CIDR:</label>
      <div class="ip-range-inputs">
        <mat-form-field class="full-width input-field" appearance="outline">
          <input matInput [(ngModel)]="startIP">
          <mat-hint>e.g. 192.168.2.0/24</mat-hint>
        </mat-form-field>
        <span class="range-separator">/</span>
        <mat-form-field class="input-field-cidr" appearance="outline">
          <input matInput [(ngModel)]="endIP">
        </mat-form-field>
      </div>
    </div>


    <div class="input-container" *ngIf="selectedFormat === 'netmask'">
      <label class="input-label">Netmask:</label>
      <div class="ip-range-inputs">
        <mat-form-field class="full-width input-field" appearance="outline">
          <input matInput [(ngModel)]="startIP">
          <mat-hint>e.g. 192.168.2.0 / 255.255.255.0</mat-hint>
        </mat-form-field>
        <span class="range-separator">/</span>
        <mat-form-field class="full-width input-field" appearance="outline">
          <input matInput [(ngModel)]="endIP">
        </mat-form-field>
      </div>
    </div>






  </mat-tab>

  <!-- DNS Configuration Tab -->
  <mat-tab label="DNS">
    <div class="dns">
      <mat-form-field class="full-width input-field" appearance="outline">
        <mat-label>DNS Name</mat-label>
        <input matInput placeholder="e.g. some-server-name" [(ngModel)]="dns">
      </mat-form-field>
    </div>

  </mat-tab>

  <!-- Device Object Configuration Tab -->
  <mat-tab label="Device Object">
    <div class="traffic-field">
      <ng-container>
            <p>Type to find a network object and filter your results</p>
            <div class="form-container">
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>Filter by name</mat-label>
                <input
                  matInput
                  [(ngModel)]="filter"
                  (ngModelChange)="addressWasChanged()"
                  placeholder="Type to filter..."
                  autofocus
                >
              </mat-form-field>
              <mat-form-field appearance="outline" class="input-field">
                <mat-label>Device Name</mat-label>
                <input
                  matInput
                  [value]="device?.display_name"
                  placeholder="No Device Available"
                  [readonly]="true"
                >
              </mat-form-field>
            </div>

        <div class="scrollable-list-container">
          <mat-list class="scrollable-list">

            <!-- Loading spinner when loading is true -->
            <mat-spinner *ngIf="loading"></mat-spinner>
            <!-- Display filtered list items if device is selected and values are available -->
            <ng-container *ngIf="!loading">
              <ng-container *ngIf="filteredValues && filteredValues.length > 0; else noData">
                <mat-list-item *ngFor="let value of paginatedValues" (click)="selectValue(value.name)" [ngClass]="{'selected': filter === value.name}">
                  {{ value.name }}
                </mat-list-item>

                <mat-paginator
                  [length]="filteredValues.length"
                  [pageSize]="itemsPerPage"
                  [pageSizeOptions]="[5, 10, 50, 100]"
                  (page)="onPageChange($event)"
                  [showFirstLastButtons]="true"
                >
                </mat-paginator>
              </ng-container>
            </ng-container>

            <!-- Display 'Keine Daten gefunden' if no data is available -->
            <ng-template #noData>
              <div>Keine Daten gefunden</div>
            </ng-template>

          </mat-list>
        </div>

      </ng-container>


    </div>
  </mat-tab>
</mat-tab-group>

<!-- Dialog Actions -->
<div mat-dialog-actions>
  <button mat-fab extended mat-dialog-close>Cancel</button>
  <button mat-fab extended mat-dialog-close (click)="saveAndClose()">OK</button>
</div>
