<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" class="custom-table" matSort>

    <!-- Edit Column (Now with edit button/icon) -->
    <ng-container matColumnDef="edit">
      <th mat-header-cell *matHeaderCellDef> Edit </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button (click)="editRule(element)">
          <mat-icon>edit</mat-icon>
        </button>
      </td>
    </ng-container>

    <!-- Number Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef> # </th>
      <td mat-cell *matCellDef="let element; let i = index"> {{i + 1}} </td>
    </ng-container>

    <!-- Enabled Column -->
    <ng-container matColumnDef="enabled">
      <th mat-header-cell *matHeaderCellDef> Enabled </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox [checked]="element.enable === 'enabled'" disabled="true"></mat-checkbox>
      </td>
    </ng-container>

    <!-- Source Column -->
    <ng-container matColumnDef="source">
      <th mat-header-cell *matHeaderCellDef> Source </th>
      <td mat-cell *matCellDef="let element"> {{element.source.join(', ')}} </td>
    </ng-container>

    <!-- User Column -->
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef> User </th>
      <td mat-cell *matCellDef="let element"> {{element.user.join(', ')}} </td>
    </ng-container>

    <!-- Destination Column -->
    <ng-container matColumnDef="destination">
      <th mat-header-cell *matHeaderCellDef> Destination </th>
      <td mat-cell *matCellDef="let element"> {{element.destination.join(', ')}}  </td>
    </ng-container>

    <!-- Service Column -->
    <ng-container matColumnDef="service">
      <th mat-header-cell *matHeaderCellDef> Service </th>
      <td mat-cell *matCellDef="let element"> {{element.service.join(', ')}} </td>
    </ng-container>

    <!-- Action Column -->
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef> Action </th>
      <td mat-cell *matCellDef="let element">
        <span [ngClass]="{'deny': element.action === 'deny', 'permit': element.action === 'permit'}">{{element.action}}</span>
      </td>
    </ng-container>

    <!-- Logging Column -->
    <ng-container matColumnDef="logging">
      <th mat-header-cell *matHeaderCellDef> Logging </th>
      <td mat-cell *matCellDef="let element"> {{element.log}} </td>
    </ng-container>

    <!-- Time Column -->
    <ng-container matColumnDef="time">
      <th mat-header-cell *matHeaderCellDef> Time </th>
      <td mat-cell *matCellDef="let element">{{element.time.join(', ')}}</td>
    </ng-container>

    <!-- Comment Column -->
    <ng-container matColumnDef="comment">
      <th mat-header-cell *matHeaderCellDef> Comment </th>
      <td mat-cell *matCellDef="let element">{{element.comments.join(', ')}}</td>
    </ng-container>

    <!-- CLI View Column -->
    <ng-container matColumnDef="cliView">
      <th mat-header-cell *matHeaderCellDef> CLI View </th>
      <td mat-cell *matCellDef="let element">{{element.cli}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

</div>
