<h2 mat-dialog-title>Choose Service</h2>
<mat-tab-group>
<!--  <mat-tab label="Common">-->
<!--    <mat-form-field class="full-width">-->
<!--      <mat-label>Find</mat-label>-->
<!--      <input matInput placeholder="Search service">-->
<!--    </mat-form-field>-->
<!--    <mat-selection-list>-->
<!--      <mat-list-option *ngFor="let service of commonServices">{{service}}</mat-list-option>-->
<!--    </mat-selection-list>-->
<!--  </mat-tab>-->
<!--  <mat-tab label="Other">-->
<!--    <mat-radio-group>-->
<!--      <mat-radio-button value="tcp">TCP</mat-radio-button>-->
<!--      <mat-radio-button value="udp">UDP</mat-radio-button>-->
<!--      <mat-radio-button value="icmp">ICMP</mat-radio-button>-->
<!--      <mat-radio-button value="any">Any</mat-radio-button>-->
<!--    </mat-radio-group>-->
<!--    <mat-form-field class="full-width">-->
<!--      <mat-label>Port Number</mat-label>-->
<!--      <input matInput placeholder="e.g. 80">-->
<!--    </mat-form-field>-->
<!--  </mat-tab>-->
  <mat-tab label="Device Service">

    <div class="traffic-field">
      <ng-container>
        <p>Type to find a network object and filter your results</p>
        <div class="form-container">
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>Filter by name</mat-label>
            <input
              matInput
              [(ngModel)]="filter"
              (ngModelChange)="addressWasChanged()"
              placeholder="Type to filter..."
              autofocus
            >
          </mat-form-field>
          <mat-form-field appearance="outline" class="input-field">
            <mat-label>Device Name</mat-label>
            <input
              matInput
              [value]="device?.display_name"
              placeholder="No Device Available"
              [readonly]="true"
            >
          </mat-form-field>
        </div>

        <div class="scrollable-list-container">
          <mat-list class="scrollable-list">

            <!-- Loading spinner when loading is true -->
            <mat-spinner *ngIf="loading"></mat-spinner>
            <!-- Display filtered list items if device is selected and values are available -->
            <ng-container *ngIf="!loading">
              <ng-container *ngIf="filteredValues && filteredValues.length > 0; else noData">
                <mat-list-item *ngFor="let value of paginatedValues" (click)="selectValue(value.name)">
                  {{ value.name }}
                </mat-list-item>
                <mat-paginator
                  [length]="filteredValues.length"
                  [pageSize]="itemsPerPage"
                  [pageSizeOptions]="[5, 10, 50, 100]"
                  (page)="onPageChange($event)"
                  [showFirstLastButtons]="true"
                >
                </mat-paginator>
              </ng-container>
            </ng-container>

            <!-- Display 'Keine Daten gefunden' if no data is available -->
            <ng-template #noData>
              <div>Keine Daten gefunden</div>
            </ng-template>

          </mat-list>
        </div>

      </ng-container>


    </div>
  </mat-tab>
</mat-tab-group>
<div mat-dialog-actions>
  <button mat-fab extended mat-dialog-close>Cancel</button>
  <button mat-fab extended mat-dialog-close (click)="saveAndClose()">OK</button>
</div>
